
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },

    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();

    function jumpRed() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        close();
        router.push({
          name: "Introduction"
        });
        return false;

        // window.location.href = 'http://bzwz.com/myInvitation';


    }
    function close() {
      ctx.emit('close')
    }
    return {
      close,
      jumpRed
    };
  },
});
