
import { defineComponent, onMounted, ref, nextTick, watch} from "vue";
import banner from "@/components/common/Swiper.vue";

import list from "@/components/common/list.vue";

import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import productCard from "@/components/common/productCard.vue";

import MenuTab from "@/components/common/MenuTab.vue";
import Toast from "@/components/UI/Toast/";
//ts
import { strainOrCellsMap } from "@/hooks/useProduct";
import { useRouter, useRoute } from "vue-router";

//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import OrderRedPackage from "@/components/common/OrderRedPackage.vue"

export default defineComponent({
  name: "Index",
  components: {
    banner,
    list,
    newsItem,
    TopicItem,
    ClassItem,
    MenuTab,
    productCard,
    OrderRedPackage
  },

  setup() {
    const advise = ref(true)
    const router = useRouter();
    const route = useRoute();
    let timer: any = null;
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 3,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });

    function filterFunc(data: any) {
      if (data.fenlei == 205) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }

    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
    });
    //  切换列表菜单
    type Menu = "新闻" | "帖子" | "直播" | "热销";
    const menu: Menu[] = ["直播", "新闻", "帖子", "热销"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    function close() {
      advise.value = false;

    }


    watch(
        () => {
          return list.value
        },
        () => {
          timer = setTimeout(() => {
            advise.value = false
          }, 5000)
        }
    )
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    return {
      list,
      hotSaleList,
      menu,
      selectMenu,
      currentMenuItem,
      strainOrCellsMap,
      filterFunc,
      advise,
      close
    };
  },
});
