<template>
  <teleport to="body">
    <div class="invoice-red-package">
      <div class="toast"></div>
      <div class="red-package-content">
        <img class="close" @click="close" src="@/assets/img/close.png" alt="">
        <!--        <img @click.stop="onOpenPackage" :src="src" alt="" /></div>-->
        <img @click.stop="jumpRed" :src="src" alt="" /></div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },

    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();

    function jumpRed() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        close();
        router.push({
          name: "Introduction"
        });
        return false;

        // window.location.href = 'http://bzwz.com/myInvitation';


    }
    function close() {
      ctx.emit('close')
    }
    return {
      close,
      jumpRed
    };
  },
});
</script>


<style lang="scss" scoped>
.invoice-red-package {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .toast {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .red-package-content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    width: 80%;
    left: 10%;
    img{
      width: 100%;
    }
    .close{
      width: 32px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(400%);
      z-index: 100;
    }
  }
}
</style>
